export const POSITIONS_PERMISSIONS = {
  readByFilter: { module: 'Position', action: 'GetPositionsByFilter' },
  readById: { module: 'Position', action: 'GetPositionById' },
  update: { module: 'Position', action: 'UpdatePosition' },
  create: { module: 'Position', action: 'AddPosition' },
  delete: { module: 'Position', action: 'DeletePosition' },
  getComments: { module: 'Position', action: 'GetPositionComments' },
  getPositionCost: {
    module: 'Position',
    action: 'GetPositionCostByPositionId',
  },
  downloadPositionDocument: {
    module: 'Position',
    action: 'GetDownloadPositionDocument',
  },
  suggestVendors: {
    module: 'Position',
    action: 'GetVendorsForSuggestionToPosition',
  },
  addPositionComment: { module: 'Position', action: 'AddPositionComment' },
  updatePositionUrgency: {
    module: 'Position',
    action: 'UpdatePositionUrgency',
  },
  showToPartners: { module: 'Position', action: 'ShowToPartners' },
  showToRecruiters: { module: 'Position', action: 'ShowToRecruiters' },
  updateRateRegion: { module: 'Position', action: 'UpdateRateRegion' },
  getAvailableResources: {
    module: 'Position',
    action: 'GetPotentialCandidatesFilter',
  },
  updatePositionSpecificPartners: {
    module: 'Position',
    action: 'UpdatePositionSpecificPartners',
  },
};
